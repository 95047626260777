.vertical-timeline-element-icon {
    width: 20px !important;
    height: 20px !important;
    background: rgb(255, 255, 255) !important;
    box-shadow: 0 0 0 4px #000000, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;
}

.vertical-timeline-element-content {
    box-shadow: none !important;
    padding: 0 !important;
}

.vertical-timeline::before {
    left: 8px !important;
}

.vertical-timeline-element {
    position: relative;
    margin-top: 0 !important;
    margin-bottom: 25px !important;
}

.vertical-timeline-element:last-child {
    margin-bottom: 0 !important;
}